body {
    margin: 0;
    padding: 0;
}

#wr-web-main {
    width: 75%;
    border: 0px solid #ff0000;
    margin: auto;
    padding: 20px 0;
}

#wr-web-left {
    border: 0px solid #ff0000;
    width: 48%;
    float: left;
}

#wr-web-right {
    border: 0px solid #ff0000;
    width: 50%;
    float: right;
}

h1.hero {
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    font-size: 2.25em;
    color: #0094FF;
    margin: 50px 0 50px 0;
}

.wr-download-btns {
    padding-top: 30px;
    clear: both;
}

#wr-footer {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 0.75em;
    clear: both;
    border: 0px solid #ff0000;
    padding-top: 20px;
}

@media only screen and (max-width: 600px) {
    #wr-web-right {
        display: none;
    }

    #wr-web-left {
        width: 100%;
    }
}