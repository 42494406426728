* {
    margin: 0;
    padding: 0;
}

live {
    background-color: red;
    color: white;
    border-radius: 5px;
    padding-left: 7px;
    padding-right: 5px;
    padding-bottom: 3px;
    font-size: 75%;
  }